import React, {FC} from "react";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {PecOfficeIcon} from "../PecOfficeIcon";

interface ProductSelectProps {
}

export const ProductSelect: FC<ProductSelectProps> = () => (
  <FormControl sx={{width: '700px', marginTop: '16px'}}>
    <InputLabel>Prodotto</InputLabel>
    <Select
      value={'a.pecoffice'}
      label="Prodotto"
    >
      <MenuItem value={'a.pecoffice'}>
        <Box sx={{display: 'flex', gap: '8px'}}>
          <PecOfficeIcon/>
          a.pecoffice
        </Box>
      </MenuItem>
    </Select>
  </FormControl>
);