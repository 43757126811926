export interface Plan {
  name: string;
  appName: string;
  controlPanelName: string;
  appLink: string;
  controlPanelLink: string;
}

export const plans = [
  {
    name: 'Simple',
    appName: 'a.pecoffice',
    controlPanelName: 'acmp',
    appLink: 'https://apecofficedemosimple.ardesia.it/',
    controlPanelLink: 'https://acmpdemosimple.ardesia.it/',
  },
  {
    name: 'Genius',
    appName: 'a.pecoffice',
    controlPanelName: 'acmp',
    appLink: 'https://apecofficedemogenius.ardesia.it/',
    controlPanelLink: 'https://acmpdemogenius.ardesia.it/',
  },
  {
    name: 'Yourself',
    appName: 'a.pecoffice',
    controlPanelName: 'acmp',
    appLink: 'https://apecofficedemoyourself.ardesia.it/',
    controlPanelLink: 'https://acmpdemoyourself.ardesia.it/',
  },
];