import React, {FC} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Button, Card, CardActions, CardContent} from "@mui/material";
import {PecOfficeActivityLogo} from "../PecOfficeActivityLogo";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import {openInNewTab} from "../util";
import {Plan} from "../plan";


interface PlanCardProps {
  plan: Plan;
  index: number;
  isFirst: boolean;
}

export const PlanCard: FC<PlanCardProps> = ({plan, index, isFirst}) => (
  <Grid
    paddingX={'2em'}
    sx={{
      '--Grid-borderWidth': '1px',
      borderLeft: isFirst ? 'none' : 'var(--Grid-borderWidth) solid',
      borderColor: 'divider',
    }}
  >
    <Box sx={{display: 'flex', justifyContent: 'center'}} mb={'40px'}>
      <span className={'Grid-title'}>
        Piano {plan.name}
      </span>
    </Box>
    <Card>
      <CardContent  sx={{padding:'56px'}}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flexDirection: 'column'
        }}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <PecOfficeActivityLogo/>
          </Box>
          <span className='Card-subtext'>{plan.name}</span>
        </Box>
      </CardContent>
      <CardActions sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Button
          size="small"
          startIcon={<OpenInNewRoundedIcon/>}
          sx={{color: '#02A17C'}}
          onClick={() => openInNewTab(plan.appLink)}
        >
          accedi {plan.appName}
        </Button>
        <Button
          size="small"
          startIcon={<OpenInNewRoundedIcon/>}
          sx={{color: '#000000DE'}}
          onClick={() => openInNewTab(plan.controlPanelLink)}
        >
          accedi {plan.controlPanelName}
        </Button>
      </CardActions>
    </Card>
  </Grid>
);