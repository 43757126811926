import {FC} from "react";

export const PecOfficeIcon: FC = () => {
  return (<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Logo/Glifo">
        <g id="Group 393">
          <path id="Vector" d="M19.8339 17.3804H17.5885V19.9999H19.8339V17.3804Z" fill="#02A17C"/>
          <path id="Vector_2" d="M15.3431 14.7607H13.0978V17.3803H15.3431V14.7607Z" fill="#68C6B0"/>
          <path id="Vector_3" d="M13.0976 14.7607H10.8523V17.3803H13.0976V14.7607Z" fill="#35B496"/>
          <path id="Vector_4" d="M10.8524 14.7607H8.60706V17.3803H10.8524V14.7607Z" fill="#03A07B"/>
          <path id="Vector_5" d="M8.60717 14.7607H6.36182V17.3803H8.60717V14.7607Z" fill="#028163"/>
          <path id="Vector_6" d="M8.60717 12.1411H6.36182V14.7607H8.60717V12.1411Z" fill="#01604A"/>
          <path id="Vector_7" d="M8.60717 9.52167H6.36182V12.1412H8.60717V9.52167Z" fill="#014032"/>
          <path id="Vector_8" d="M8.60692 6.90219H6.36157V9.52177H8.60692V6.90219Z" fill="#002018"/>
          <path id="Vector_9" d="M10.8524 6.90204H8.60706V9.52162H10.8524V6.90204Z" fill="#014032"/>
          <path id="Vector_10" d="M13.0976 6.90204H10.8523V9.52162H13.0976V6.90204Z" fill="#01604A"/>
          <path id="Vector_11" d="M15.3431 6.90204H13.0978V9.52162H15.3431V6.90204Z" fill="#028163"/>
          <path id="Vector_12" d="M17.5884 6.90204H15.343V9.52162H17.5884V6.90204Z" fill="#05A17D"/>
          <path id="Vector_13" d="M17.5884 9.52167H15.343V12.1412H17.5884V9.52167Z" fill="#35B496"/>
          <path id="Vector_14" d="M17.5884 12.1411H15.343V14.7607H17.5884V12.1411Z" fill="#68C6B0"/>
        </g>
        <g id="Group 399">
          <g id="Vector_15" filter="url(#filter0_d_35_267)">
            <path d="M12.3556 9.14758L12.3556 2.91049L2.99996 2.91049L2.99996 9.14758L12.3556 9.14758Z" fill="white"/>
          </g>
          <g id="Vector_16">
            <path d="M12.3556 9.14758L12.3556 2.91049L7.67778 6.23694L12.3556 9.14758Z" fill="#E6E6E6"/>
            <path d="M12.3556 9.14758L12.3556 2.91049L7.67778 6.23694L12.3556 9.14758Z"
                  fill="url(#paint0_linear_35_267)"/>
          </g>
          <g id="Vector_17">
            <path d="M3 9.14758L3 2.91049L7.67782 6.23694L3 9.14758Z" fill="#E6E6E6"/>
            <path d="M3 9.14758L3 2.91049L7.67782 6.23694L3 9.14758Z" fill="url(#paint1_linear_35_267)"/>
          </g>
          <path id="Vector_18" d="M2.99996 2.91064L12.3556 2.91064L7.67778 -4.08948e-07L2.99996 2.91064Z"
                fill="#CCCCCC"/>
          <path id="Vector_19" d="M2.99996 9.1478L12.3556 9.1478L7.67778 6.23716L2.99996 9.1478Z" fill="#B3B2B2"/>
        </g>
        <g id="Group 398">
          <g id="Vector_20" filter="url(#filter1_dd_35_267)">
            <path d="M13.2988 12.0788L14.8485 6.29526L6.17314 3.9707L4.62343 9.75428L13.2988 12.0788Z" fill="white"/>
          </g>
          <g id="Vector_21">
            <path d="M13.2988 12.0788L14.8485 6.29526L9.68432 8.21755L13.2988 12.0788Z" fill="#E6E6E6"/>
            <path d="M13.2988 12.0788L14.8485 6.29526L9.68432 8.21755L13.2988 12.0788Z"
                  fill="url(#paint2_linear_35_267)"/>
          </g>
          <g id="Vector_22">
            <path d="M4.62351 9.75444L6.17322 3.97086L9.68439 8.21771L4.62351 9.75444Z" fill="#E6E6E6"/>
            <path d="M4.62351 9.75444L6.17322 3.97086L9.68439 8.21771L4.62351 9.75444Z"
                  fill="url(#paint3_linear_35_267)"/>
          </g>
          <g id="Vector_23" filter="url(#filter2_d_35_267)">
            <path d="M6.17314 3.9707L14.8485 6.29526L10.0717 6.77166L6.17314 3.9707Z" fill="#B3B2B2"/>
          </g>
          <path id="Vector_24" d="M4.62333 9.75421L13.2987 12.0788L9.68422 8.21748L4.62333 9.75421Z" fill="#B3B2B2"/>
        </g>
        <g id="Group 394">
          <g id="Vector_25" filter="url(#filter3_d_35_267)">
            <path d="M12.2787 13.899L14.524 10.0099L8.69045 6.64189L6.4451 10.531L12.2787 13.899Z" fill="white"/>
          </g>
          <g id="Vector_26">
            <path d="M12.2787 13.899L14.524 10.0099L10.4097 10.4001L12.2787 13.899Z" fill="#E6E6E6"/>
            <path d="M12.2787 13.899L14.524 10.0099L10.4097 10.4001L12.2787 13.899Z" fill="url(#paint4_linear_35_267)"/>
          </g>
          <g id="Vector_27">
            <path d="M6.4452 10.5309L8.69055 6.64182L10.4098 10.4L6.4452 10.5309Z" fill="#E6E6E6"/>
            <path d="M6.4452 10.5309L8.69055 6.64182L10.4098 10.4L6.4452 10.5309Z" fill="url(#paint5_linear_35_267)"/>
          </g>
          <path id="Vector_28" d="M8.69046 6.64189L14.524 10.0099L10.4097 10.4001L8.69046 6.64189Z" fill="#B3B2B2"/>
          <path id="Vector_29" d="M6.44509 10.5311L12.2787 13.8991L10.4097 10.4002L6.44509 10.5311Z" fill="#CCCCCC"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_35_267" x="0.5" y="0.410492" width="14.3556" height="11.2371" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="1.25"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_35_267"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_35_267" result="shape"/>
        </filter>
        <filter id="filter1_dd_35_267" x="2.12341" y="1.4707" width="18.0964" height="15.9794"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="1.25"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_35_267"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dx="2.68565" dy="2.68565"/>
          <feGaussianBlur stdDeviation="1.34282"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
          <feBlend mode="overlay" in2="effect1_dropShadow_35_267" result="effect2_dropShadow_35_267"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_35_267" result="shape"/>
        </filter>
        <filter id="filter2_d_35_267" x="5.5481" y="3.3457" width="9.92542" height="4.05096"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="0.3125"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_35_267"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_35_267" result="shape"/>
        </filter>
        <filter id="filter3_d_35_267" x="3.94507" y="4.14188" width="13.079" height="12.2571"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="1.25"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_35_267"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_35_267" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_35_267" x1="11.0562" y1="6.23694" x2="7.93765" y2="6.23694"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint1_linear_35_267" x1="4.29939" y1="6.23694" x2="7.41794" y2="6.23694"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint2_linear_35_267" x1="12.8171" y1="9.05698" x2="9.9253" y2="8.28213"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint3_linear_35_267" x1="6.55162" y1="7.37829" x2="9.44341" y2="8.15314"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint4_linear_35_267" x1="12.5163" y1="11.6163" x2="10.5718" y2="10.4936"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint5_linear_35_267" x1="8.30325" y1="9.18376" x2="10.2478" y2="10.3064"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
      </defs>
    </svg>
  )
}