import {FC} from "react";

export const PecOfficeActivityLogo: FC = () => {
  return (

    <svg height="80px" viewBox="0 0 446 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M112.932 111.334H98.646V128H112.932V111.334Z" fill="#02A17C"/>
      <path d="M84.3604 94.667H70.0746V111.333H84.3604V94.667Z" fill="#68C6B0"/>
      <path d="M70.0747 94.667H55.7889V111.333H70.0747V94.667Z" fill="#35B496"/>
      <path d="M55.789 94.667H41.5032V111.333H55.789V94.667Z" fill="#03A07B"/>
      <path d="M41.5033 94.667H27.2175V111.333H41.5033V94.667Z" fill="#028163"/>
      <path d="M41.5033 78H27.2175V94.667H41.5033V78Z" fill="#01604A"/>
      <path d="M41.5033 61.3335H27.2175V78H41.5033V61.3335Z" fill="#014032"/>
      <path d="M41.5033 44.6667H27.2175V61.3334H41.5033V44.6667Z" fill="#002018"/>
      <path d="M55.789 44.6667H41.5032V61.3334H55.789V44.6667Z" fill="#014032"/>
      <path d="M70.0747 44.6667H55.7889V61.3334H70.0747V44.6667Z" fill="#01604A"/>
      <path d="M84.3604 44.6667H70.0746V61.3334H84.3604V44.6667Z" fill="#028163"/>
      <path d="M98.646 44.6667H84.3604V61.3334H98.646V44.6667Z" fill="#05A17D"/>
      <path d="M98.646 61.3335H84.3604V78H98.646V61.3335Z" fill="#35B496"/>
      <path d="M98.646 78H84.3604V94.667H98.646V78Z" fill="#68C6B0"/>
      <g filter="url(#filter0_f_1_71)">
        <path d="M65.3549 58.9521V19.2695H5.83112V58.9521H65.3549Z" fill="white"/>
      </g>
      <path d="M65.3549 58.9521V19.2695L35.593 40.4336L65.3549 58.9521Z" fill="#E6E6E6"/>
      <path d="M65.3549 58.9521V19.2695L35.593 40.4336L65.3549 58.9521Z" fill="url(#paint0_linear_1_71)"/>
      <path d="M5.83112 58.9521V19.2695L35.593 40.4336L5.83112 58.9521Z" fill="#E6E6E6"/>
      <path d="M5.83112 58.9521V19.2695L35.593 40.4336L5.83112 58.9521Z" fill="url(#paint1_linear_1_71)"/>
      <path d="M5.83112 19.2696H65.3549L35.593 0.751099L5.83112 19.2696Z" fill="#CCCCCC"/>
      <path d="M5.83112 58.9522L65.3549 58.9521L35.593 40.4336L5.83112 58.9522Z" fill="#B3B2B2"/>
      <g filter="url(#filter1_f_1_71)">
        <path d="M71.3561 77.603L81.2159 40.8058L26.0201 26.0161L16.1603 62.8133L71.3561 77.603Z"
              fill="white"/>
      </g>
      <path d="M71.3561 77.6031L81.2159 40.8059L48.3594 53.0362L71.3561 77.6031Z" fill="#E6E6E6"/>
      <path d="M71.3561 77.6031L81.2159 40.8059L48.3594 53.0362L71.3561 77.6031Z"
            fill="url(#paint2_linear_1_71)"/>
      <path d="M16.161 62.8135L26.0208 26.0164L48.3601 53.0364L16.161 62.8135Z" fill="#E6E6E6"/>
      <path d="M16.161 62.8135L26.0208 26.0164L48.3601 53.0364L16.161 62.8135Z"
            fill="url(#paint3_linear_1_71)"/>
      <path d="M26.0201 26.0161L81.2159 40.8058L50.8244 43.8368L26.0201 26.0161Z" fill="#B3B2B2"/>
      <path d="M16.1605 62.8136L71.3563 77.6032L48.3596 53.0364L16.1605 62.8136Z" fill="#B3B2B2"/>
      <g filter="url(#filter2_f_1_71)">
        <path d="M64.8648 89.181L79.1506 64.4374L42.0352 43.0088L27.7495 67.7524L64.8648 89.181Z"
              fill="white"/>
      </g>
      <path d="M64.8648 89.181L79.1506 64.4374L52.9738 66.9197L64.8648 89.181Z" fill="#E6E6E6"/>
      <path d="M64.8648 89.181L79.1506 64.4374L52.9738 66.9197L64.8648 89.181Z"
            fill="url(#paint4_linear_1_71)"/>
      <path d="M27.7495 67.7525L42.0352 43.0089L52.9739 66.9198L27.7495 67.7525Z" fill="#E6E6E6"/>
      <path d="M27.7495 67.7525L42.0352 43.0089L52.9739 66.9198L27.7495 67.7525Z"
            fill="url(#paint5_linear_1_71)"/>
      <path d="M42.0352 43.0088L79.1506 64.4374L52.9738 66.9197L42.0352 43.0088Z" fill="#B3B2B2"/>
      <path d="M27.7495 67.7524L64.8649 89.181L52.9738 66.9197L27.7495 67.7524Z" fill="#CCCCCC"/>
      <path
        d="M199.797 63.5504C192.75 63.5504 189.848 70.5241 189.848 77.0708C189.848 85.041 193.441 90.307 199.797 90.307C206.153 90.307 209.607 84.756 209.607 76.6439C209.607 68.8163 206.015 63.5504 199.797 63.5504ZM200.902 58.5693C210.298 58.5693 215.687 66.8238 215.687 76.6439C215.687 86.322 210.713 95.288 200.488 95.288C196.757 95.288 192.75 93.865 190.539 90.307V107.954H184.874V59.4232H190.401V64.1197C192.336 60.2771 196.205 58.5693 200.902 58.5693Z"
        fill="#02A17C"/>
      <path
        d="M343.222 51.0263C342.117 50.7416 341.15 50.5993 340.044 50.5993C336.728 50.5993 335.485 51.5956 335.485 55.2959V59.2808H342.117V64.1197H335.485V94.434H329.819V64.262H324.292V59.4232H329.819C329.819 53.7304 329.819 52.8764 329.958 52.3072C330.648 48.0376 333.274 45.3335 339.215 45.3335C340.459 45.3335 342.393 45.6181 343.775 45.9028L343.222 51.0263Z"
        fill="#02A17C"/>
      <path
        d="M367.541 59.4232H373.206V94.576H367.541V59.4232ZM367.541 46.0451H373.206V53.0188H367.541V46.0451ZM363.948 51.0263C362.843 50.7416 361.876 50.5993 360.77 50.5993C357.454 50.5993 356.211 51.5956 356.211 55.2959V59.2808H362.843V64.1197H356.211V94.434H350.546V64.262H345.019V59.4232H350.546V54.8689C350.546 53.588 350.546 52.7341 350.684 52.3072C351.375 48.0376 354 45.3335 359.941 45.3335C361.185 45.3335 363.119 45.6181 364.501 45.9028L363.948 51.0263Z"
        fill="#02A17C"/>
      <path
        d="M280.491 80.6289C279.385 87.0329 275.517 90.3069 270.542 90.3069C264.739 90.3069 260.317 85.7519 260.317 77.0707C260.317 68.2468 264.739 63.6926 270.542 63.6926C275.517 63.6926 279.662 67.2506 280.629 73.7973H286.571C285.603 65.5428 280.353 58.7114 270.542 58.7114C258.107 58.7114 254.238 69.2431 254.238 77.0707C254.238 87.8869 260.179 95.4299 270.542 95.4299C280.629 95.4299 285.327 88.5989 286.571 80.7709H280.491V80.6289Z"
        fill="#02A17C"/>
      <path
        d="M306.191 58.5693C295.414 58.5693 291.13 66.3968 290.163 73.6552H296.243C297.21 66.9661 301.217 63.5504 306.33 63.5504C312.133 63.5504 316.693 68.247 316.693 76.7862C316.693 85.61 312.271 90.164 306.33 90.164C301.355 90.164 297.487 86.749 296.381 80.487H290.301C291.545 89.31 297.21 95.145 306.33 95.145C317.936 95.145 322.634 85.895 322.634 76.5015C322.496 67.1084 317.245 58.5693 306.191 58.5693Z"
        fill="#02A17C"/>
      <path
        d="M405.125 80.6289C404.019 87.0329 400.012 90.3069 395.176 90.3069C389.373 90.3069 384.951 85.7519 384.951 77.0707C384.951 68.2468 389.373 63.6926 395.176 63.6926C400.15 63.6926 404.296 67.2506 405.263 73.7973H411.204C410.237 65.5428 404.986 58.7114 395.176 58.7114C382.74 58.7114 378.871 69.2431 378.871 77.0707C378.871 87.8869 384.813 95.4299 395.176 95.4299C405.263 95.4299 409.961 88.5989 411.204 80.7709H405.125V80.6289Z"
        fill="#02A17C"/>
      <path d="M302.046 73.6553H296.243V80.6291H302.046V73.6553Z" fill="#02A17C"/>
      <path
        d="M225.221 73.5128H244.566C244.428 68.5316 241.388 63.4081 235.032 63.4081C229.228 63.5504 225.636 67.82 225.221 73.5128ZM250.645 75.0783C250.645 75.9322 250.645 77.4978 250.645 78.494H225.221C225.221 85.468 229.09 90.164 235.584 90.164C240.006 90.164 243.184 88.029 244.566 83.048L250.231 83.475C248.435 91.018 243.184 95.145 235.446 95.145C225.359 95.145 219.142 87.602 219.142 76.9285C219.142 68.5316 223.563 58.4269 235.17 58.4269C244.842 58.5692 250.231 65.9699 250.645 75.0783Z"
        fill="#02A17C"/>
      <path
        d="M420.324 73.3706H439.668C439.53 68.3894 436.49 63.2658 430.134 63.2658C424.331 63.4082 420.738 67.6778 420.324 73.3706ZM445.748 74.9361C445.748 75.79 445.748 77.3555 445.748 78.352H420.324C420.324 85.325 424.192 90.022 430.687 90.022C435.108 90.022 438.286 87.887 439.668 82.906L445.333 83.333C443.537 90.876 438.286 95.003 430.549 95.003C420.462 95.003 414.244 87.46 414.244 76.7862C414.244 68.3894 418.666 58.2847 430.272 58.2847C439.944 58.427 445.333 65.8276 445.748 74.9361Z"
        fill="#02A17C"/>
      <path d="M173.267 94.1489H167.602V100.98H173.267V94.1489Z" fill="#FF3300"/>
      <path
        d="M162.075 86.607V80.629V80.487V76.3597H161.937C159.864 77.6406 156.272 78.495 152.265 78.921C147.567 79.348 144.942 80.914 144.942 84.899C144.942 88.315 147.705 90.165 151.574 90.165C154.476 90.165 156.825 89.311 158.483 88.172V93.153C155.995 94.577 153.232 95.146 150.33 95.146C143.56 95.146 139 91.446 139 84.899C139 78.352 143.836 75.2211 151.298 74.6519C157.792 74.2249 161.108 72.6594 162.075 70.9516C162.075 70.3823 162.075 69.9553 162.075 69.5284C162.075 65.2588 159.035 63.5509 154.199 63.5509C149.225 63.5509 146.323 65.4011 145.632 70.3823L139.967 69.9553C140.934 62.1277 146.185 58.8544 154.614 58.8544C162.075 58.8544 167.602 62.4124 167.602 68.6744V86.749L162.075 86.607Z"
        fill="#1A171B"/>
      <defs>
        <filter id="filter0_f_1_71" x="0.116836" y="13.5553" width="70.9524" height="51.1112"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2.85714" result="effect1_foregroundBlur_1_71"/>
        </filter>
        <filter id="filter1_f_1_71" x="10.446" y="20.3018" width="76.4842" height="63.0155"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2.85714" result="effect1_foregroundBlur_1_71"/>
        </filter>
        <filter id="filter2_f_1_71" x="22.0352" y="37.2945" width="62.8297" height="57.6008"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2.85714" result="effect1_foregroundBlur_1_71"/>
        </filter>
        <linearGradient id="paint0_linear_1_71" x1="57.0877" y1="40.4336" x2="37.2464" y2="40.4336"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1_71" x1="14.0983" y1="40.4336" x2="33.9395" y2="40.4336"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1_71" x1="68.2913" y1="58.377" x2="49.8927" y2="53.4471"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1_71" x1="28.4283" y1="47.6956" x2="46.8269" y2="52.6255"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1_71" x1="66.3766" y1="74.6578" x2="54.0048" y2="67.5149"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint5_linear_1_71" x1="39.5711" y1="59.1817" x2="51.9429" y2="66.3245"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
      </defs>
    </svg>
  )
}