import {FC} from "react";

export const AdemoLogo: FC = ()=>{
  return (
    <svg width="249" height="80" viewBox="0 0 249 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 420">
        <g id="Group 419">
          <g id="demo">
            <path id="Union" fillRule="evenodd" clipRule="evenodd"
                  d="M197.644 43.7192C197.644 36.9272 199.532 31.8966 203.308 28.6272C206.462 25.9104 210.307 24.552 214.843 24.552C218.329 24.552 221.376 25.3447 223.981 26.93V35.3848C223.569 34.5801 223.078 33.8587 222.509 33.2204C220.46 30.872 217.905 29.6978 214.843 29.6978C211.734 29.6978 209.156 30.8605 207.107 33.1859C205.058 35.5113 204.033 39.0224 204.033 43.7192C204.033 48.416 205.058 51.9387 207.107 54.2871C209.156 56.6125 211.734 57.7752 214.843 57.7752C217.928 57.7752 220.495 56.601 222.544 54.2525C224.593 51.9041 225.618 48.324 225.618 43.512C225.618 40.2231 225.073 37.5152 223.983 35.3885H230.791V26.929H237.84V35.3885H230.798C231.604 37.6738 232.007 40.2781 232.007 43.2012C232.007 47.921 231.293 51.6394 229.866 54.3562C228.461 57.0499 226.4 59.1451 223.684 60.6416C220.99 62.1381 218.043 62.8864 214.843 62.8864C209.708 62.8864 205.553 61.2402 202.375 57.9478C199.221 54.6555 197.644 49.9126 197.644 43.7192Z"
                  fill="black" fillOpacity="0.6"/>
            <path id="Vector"
                  d="M141.04 62.0576V25.3809H146.6V30.5267C147.752 28.7308 149.283 27.2918 151.194 26.2097C153.105 25.1046 155.28 24.552 157.721 24.552C160.438 24.552 162.659 25.1161 164.386 26.2443C166.136 27.3724 167.368 28.9496 168.081 30.9756C170.982 26.6932 174.758 24.552 179.409 24.552C183.047 24.552 185.844 25.5651 187.801 27.5912C189.758 29.5942 190.737 32.6909 190.737 36.8812V62.0576H184.555V38.9533C184.555 36.4668 184.348 34.6824 183.933 33.6003C183.542 32.4952 182.817 31.6088 181.758 30.9411C180.698 30.2734 179.455 29.9396 178.028 29.9396C175.449 29.9396 173.308 30.803 171.604 32.5297C169.9 34.2335 169.048 36.9733 169.048 40.7492V62.0576H162.832V38.2281C162.832 35.4652 162.326 33.3931 161.312 32.0117C160.299 30.6303 158.642 29.9396 156.339 29.9396C154.59 29.9396 152.966 30.4 151.47 31.321C149.996 32.2419 148.926 33.5888 148.258 35.3616C147.59 37.1345 147.257 39.6901 147.257 43.0285V62.0576H141.04Z"
                  fill="black" fillOpacity="0.6"/>
            <path id="Vector_2"
                  d="M126.777 50.2464L133.201 51.0407C132.188 54.7936 130.311 57.7061 127.571 59.7782C124.832 61.8504 121.332 62.8864 117.073 62.8864C111.708 62.8864 107.449 61.2402 104.294 57.9478C101.163 54.6324 99.5977 49.9932 99.5977 44.03C99.5977 37.8597 101.186 33.0708 104.364 29.6633C107.541 26.2558 111.662 24.552 116.727 24.552C121.631 24.552 125.637 26.2212 128.746 29.5597C131.854 32.8981 133.408 37.5949 133.408 43.6502C133.408 44.0185 133.396 44.5711 133.373 45.3079H106.021C106.251 49.337 107.391 52.4222 109.44 54.5634C111.489 56.7046 114.045 57.7752 117.107 57.7752C119.386 57.7752 121.332 57.1765 122.944 55.9793C124.555 54.7821 125.833 52.8711 126.777 50.2464ZM106.367 40.1966H126.846C126.57 37.1114 125.787 34.7976 124.498 33.255C122.518 30.8605 119.951 29.6633 116.796 29.6633C113.941 29.6633 111.535 30.6188 109.578 32.5297C107.644 34.4407 106.574 36.9963 106.367 40.1966Z"
                  fill="black" fillOpacity="0.6"/>
            <path id="Vector_3"
                  d="M86.0942 62.0576V57.4298C83.7688 61.0676 80.3498 62.8864 75.8372 62.8864C72.9132 62.8864 70.2194 62.0806 67.7559 60.4689C65.3153 58.8573 63.4159 56.6125 62.0575 53.7345C60.7221 50.8335 60.0544 47.5066 60.0544 43.7538C60.0544 40.093 60.6646 36.7776 61.8848 33.8076C63.1051 30.8145 64.9355 28.5236 67.376 26.935C69.8165 25.3464 72.5448 24.552 75.5609 24.552C77.7711 24.552 79.7397 25.024 81.4664 25.968C83.1932 26.8889 84.5977 28.0977 85.6798 29.5942V11.4286H91.8616V62.0576H86.0942ZM66.4435 43.7538C66.4435 48.4506 67.4335 51.9617 69.4136 54.2871C71.3936 56.6125 73.7305 57.7752 76.4243 57.7752C79.1411 57.7752 81.4434 56.67 83.3314 54.4598C85.2423 52.2265 86.1978 48.8305 86.1978 44.2718C86.1978 39.2526 85.2308 35.5689 83.2968 33.2204C81.3628 30.872 78.9799 29.6978 76.148 29.6978C73.3851 29.6978 71.0713 30.826 69.2063 33.0823C67.3645 35.3386 66.4435 38.8958 66.4435 43.7538Z"
                  fill="black" fillOpacity="0.6"/>
          </g>
          <g id="Group 418">
            <path id="Vector_4" d="M47.8914 61.3024H41.8633V68.5714H47.8914V61.3024Z" fill="#FF3300"/>
            <path id="Vector_5"
                  d="M35.9822 53.2767V46.9163V46.7649V42.3731H35.8352C33.6297 43.7361 29.807 44.6447 25.5432 45.099C20.5443 45.5534 17.7508 47.2192 17.7508 51.4594C17.7508 55.094 20.6913 57.0626 24.8081 57.0626C27.8957 57.0626 30.3951 56.154 32.1595 54.9425V60.2429C29.513 61.7572 26.5724 62.363 23.4849 62.363C16.2805 62.363 11.4286 58.4256 11.4286 51.4594C11.4286 44.4933 16.5746 41.1616 24.5141 40.5559C31.4244 40.1016 34.953 38.4358 35.9822 36.6185C35.9822 36.0127 35.9822 35.5584 35.9822 35.1041C35.9822 30.561 32.7476 28.7437 27.6016 28.7437C22.3086 28.7437 19.2211 30.7124 18.4859 36.0127L12.4578 35.5584C13.487 27.2293 19.074 23.7462 28.0427 23.7462C35.9822 23.7462 41.8633 27.5322 41.8633 34.1955V53.4281L35.9822 53.2767Z"
                  fill="#1A171B"/>
          </g>
        </g>
      </g>
    </svg>
  )
}