import React from 'react';
import './App.css';
import {
  Box,
  Container,
  Typography
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {AdemoLogo} from "./AdemoLogo";
import {ProductSelect} from "./components/ProductSelect";
import {PlanCard} from "./components/PlanCard";
import {plans} from "./plan";

function App() {
  return (
    <Container maxWidth={false} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '95%',width:'95%'}}>
      <Box p={'40px'} sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
      }}>
        <Box pb={5} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <AdemoLogo/>
        </Box>
        <Typography variant={'h5'}>Seleziona il prodotto Ardesia</Typography>
        <ProductSelect/>
      </Box>
      <Grid container spacing={3} justifyContent="center" sx={{flexGrow: '1'}}>
        {plans.map((plan, index) => (
          <PlanCard key={index} plan={plan} index={index} isFirst={index === 0}/>
        ))}
      </Grid>
    </Container>
  );
}

export default App;
